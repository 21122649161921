import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCertList, setCertCodeSubscribedList, setCertCodeInFocus } from "../../redux/slices/practiceSlice";
import { setIsExamInProgress, setLatestExamType } from "../../redux/slices/examSlice";
import { login, primaryRole } from "../../redux/slices/userSlice";
import TextField from "../../components/customMui/TextField";
import LoadingButton from "../../components/customMui/LoadingButton";
import Alert from "../../components/customMui/Alert";
import standardBizApiClient from "../../util/BizApi/standardBizApiClient";
import pagePath from "../../util/constants/pagePath";
import "./login.css";


const Login = () => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const [emailAddress, setEmailAddress] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);

    const dispatch = useDispatch();

    const handleLogin = async e => {
        e.preventDefault();
        setErrorMessage(null);
        setSubmitLoading(true);

        const loginRes = await standardBizApiClient.account_login(emailAddress, password);
        if (loginRes?.is_error) {
            setErrorMessage("Error while logging in, please check your email and password.");
            setSubmitLoading(false);
            return;
        }

        const { certs, primary_role, exam_meta } = loginRes;
        const { cert_code_in_focus, cert_code_subscribed_list, cert_list } = certs;
        const { exam_type } = exam_meta;
        const newIsExamInProgress = Boolean(exam_type);

        dispatch(setCertCodeInFocus(cert_code_in_focus));
        dispatch(setCertCodeSubscribedList(cert_code_subscribed_list));
        dispatch(setCertList(cert_list));

        dispatch(setLatestExamType(exam_type));
        dispatch(setIsExamInProgress(newIsExamInProgress));

        dispatch(login());
        dispatch(primaryRole(primary_role));
    };

    return (
        <div className="login">
            <h2 className="font-header-light">Login</h2>
            <form className="login-form" onSubmit={handleLogin}>
                {errorMessage ? (<Alert severity="error">{errorMessage}</Alert>) : null}
                <TextField
                    required
                    disabled={submitLoading}
                    value={emailAddress}
                    onChange={e => setEmailAddress(e.target.value)}
                    id="login-email-address"
                    type="email"
                    label="Email Address"
                />
                <TextField
                    required
                    disabled={submitLoading}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    id="login-password"
                    type="password"
                    label="Password"
                />
                <LoadingButton customProps={{ type: "submit", loading: submitLoading }}>Login</LoadingButton>
                <p style={{ textAlign: "center" }}>Forgot your password? Reset it <Link to={pagePath.reset_password_initiate}>here</Link></p>
                <div className="login-divider"><div className="line"></div><div>or</div> <div className="line"></div></div>
                <p style={{ textAlign: "center" }}>Don't have an account? <Link to={pagePath.signup}>Sign up</Link></p>
            </form>
        </div>

    );
};
export default Login;